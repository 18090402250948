import React from 'react';

const TiktokIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="38" height="43" viewBox="0 0 29 33" fill="none" {...props}>
    <path
      d="M24.2182 8.06483C21.4437 7.44868 19.3819 5.03686 19.1188 2.10572V1.48956H14.4607V20.6784C14.4607 22.923 12.6958 24.7363 10.5322 24.7275C9.31037 24.7275 8.16492 24.1289 7.42674 23.1255C6.12008 21.3386 6.45099 18.786 8.17341 17.4304C9.19158 16.6294 10.5152 16.3917 11.7285 16.7966V11.885C7.04493 11.216 2.72615 14.6049 2.07282 19.4725C1.42797 24.3314 4.69463 28.8117 9.38674 29.4895C14.0704 30.1584 18.3891 26.7696 19.0425 21.9019C19.0934 21.497 19.1188 21.0921 19.1188 20.6872V10.8815C21.0025 12.2811 23.2594 13.0293 25.5758 13.0205V8.21447C25.1176 8.21447 24.6679 8.17046 24.2182 8.06483Z"
      fill="#1A1A1A"
      stroke="black"
    />
    <path
      d="M22.646 7.01539C19.8715 6.39924 17.8096 3.98743 17.5466 1.05628V0.440125H12.8885V19.629C12.8885 21.8736 11.1236 23.6868 8.95998 23.678C7.73817 23.678 6.59272 23.0795 5.85454 22.076C4.54787 20.2892 4.87878 17.7365 6.6012 16.381C7.61938 15.58 8.94301 15.3423 10.1563 15.7472V10.8356C5.47272 10.1666 1.15395 13.5555 0.500615 18.4231C-0.144231 23.2819 3.12242 27.7623 7.81453 28.44C12.4982 29.109 16.8169 25.7201 17.4703 20.8525C17.5212 20.4476 17.5466 20.0427 17.5466 19.6378V9.83211C19.4302 11.2317 21.6872 11.9799 24.0036 11.9711V7.16503C23.5454 7.16503 23.0957 7.12102 22.646 7.01539Z"
      fill="white"
      stroke="black"
    />
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="24.4278" height="28.9506" fill="white" transform="translate(1.5722 1.04944)" />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect width="24.4278" height="28.9506" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TiktokIcon;
