import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ALink } from 'src/components/ALink';
import { Discord, Instagram, Opensea, Tiktok, Twitter, Youtube } from 'src/config/images';

// eslint-disable-next-line react/display-name
const WeKnow = forwardRef((_props, ref: any) => {
  const [isMobile, setIsMoblie] = useState(false);

  useEffect(() => {
    const handleWindowsResize = () => {
      setIsMoblie(window.innerWidth < 428);
    };

    window.addEventListener('resize', handleWindowsResize);
    return () => {
      window.removeEventListener('resize', handleWindowsResize);
    };
  }, []);

  return (
    <Container ref={ref} data-bgcolor="#AFC67B">
      <Title>
        We know WHAT {isMobile ? '' : <br/>}you want to… {isMobile ? '' : <br/>}we’re all in the club!
      </Title>
      <SocialLinkContainer>
        <ALink link="/">
          <NavLink>
            <Twitter />
          </NavLink>
        </ALink>
        <ALink link="/">
          <NavLink>
            <Discord />
          </NavLink>
        </ALink>
        <ALink link="/">
          <NavLink>
            <Opensea />
          </NavLink>
        </ALink>
        <ALink link="/">
          <NavLink>
            <Youtube />
          </NavLink>
        </ALink>
        <ALink link="/">
          <NavLink>
            <Tiktok />
          </NavLink>
        </ALink>
        <ALink link="/">
          <NavLink>
            <Instagram />
          </NavLink>
        </ALink>
      </SocialLinkContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.16vw 10px;

  @media (max-width: 744px) {
    padding-top: 17.6vw;
    padding-bottom: 17.6vw;
  }

  @media (max-width: 428px) {
    padding-top: 27.2vw;
    padding-bottom: 27.2vw;
  }
`;

const Title = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 8.7vw;
  text-align: center;
  text-transform: uppercase;
  color: #b6413f;
  line-height: 80%;
  max-width: 84vw;
  margin: 0;
  margin-bottom: 60px;

  @media (max-width: 1439px) {
    font-size: 9vw;
    line-height: 80%;
    max-width: 90vw;
  }

  @media (max-width: 744px) {
    margin-bottom: 9.4vw;
  }

  @media (max-width: 428px) {
    font-size: 17.9vw;
    line-height: 85%;
    max-width: 90vw;
    margin-bottom: 21px;
  }
`;

const SocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 43px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 592px) {
    gap: unset;
    justify-content: space-between;
    width: 100%;
  }
`;

const NavLink = styled.div`
  cursor: pointer;
  svg {
    path {
      transition: all ease 0.3s;
    }
  }
  &:hover {
    svg {
      transition: all linear 1s;
      path:nth-child(even) {
        fill: #b6413f;
      }
    }
  }

  height: 47px;

  @media (max-width: 592px) {
    height: 39px;
  }

  svg {
    height: 100%;
  }
`;

export default WeKnow;
