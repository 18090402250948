import styled from 'styled-components';
import { StyledButton } from 'src/components/Button/WalletConnectButton';
import { NavLink } from 'react-router-dom';
import { ALink } from 'src/components/ALink';
import { Discord, Instagram, Opensea, Tiktok, Twitter, Youtube } from 'src/config/images';

export const Menu = ({ 
  handleMenuClose 
} : {
  handleMenuClose: () => void,
}) => {
  return (
    <Container>
      <StyledButton className='btn-close' onClick={handleMenuClose}>
        Close</StyledButton>
      <MenuContainer>
        <MenuItem onClick={handleMenuClose} to="/collection">Collection</MenuItem>
        <MenuItem onClick={handleMenuClose} to="/mint">Mint</MenuItem>
        <MenuItem onClick={handleMenuClose} to="/">News</MenuItem>
        <MenuItem onClick={handleMenuClose} to="/">Faq</MenuItem>
        <MenuItem onClick={handleMenuClose} to="/">Join us</MenuItem>
      </MenuContainer>
      <SocialLinkContainer>
        <ALink link="/">
          <SocialLink>
            <Twitter />
          </SocialLink>
        </ALink>
        <ALink link="/">
          <SocialLink>
            <Discord />
          </SocialLink>
        </ALink>
        <ALink link="/">
          <SocialLink>
            <Opensea />
          </SocialLink>
        </ALink>
        <ALink link="/">
          <SocialLink>
            <Youtube />
          </SocialLink>
        </ALink>
        <ALink link="/">
          <SocialLink>
            <Tiktok />
          </SocialLink>
        </ALink>
        <ALink link="/">
          <SocialLink>
            <Instagram />
          </SocialLink>
        </ALink>
      </SocialLinkContainer>
    </Container>
  )
}

const Container = styled.div`
  background: #95A3BA;
  text-align: center;
  min-height: 100vh;
  padding: 69px 24px;

  @media (max-width: 428px) {
    padding-top: 36px;
  }

  .btn-close {
    font-size: 15px;
    font-weight: 600;
    padding: 20px;
    line-height: 0.5;
  }
`;

const MenuContainer = styled.div`
  margin-top: 86px;
  margin-bottom: 66px;

  @media (max-width: 528px) {
    margin-bottom: 86px;
  }
`

const MenuItem = styled(NavLink)`
  display: block;
  font-size: 75px;
  line-height: 53px;
  font-family: Wolfskin;
  font-weight: 400;
  text-transform: uppercase;
  color: #FFF9B5;
  text-decoration: none;
  margin-bottom: 32px;

  &:hover {
    color: #b6413f;
  }

  &::last-child {
    margin-bottom: 0;
  }
`;

const SocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 43px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 592px) {
    gap: unset;
    justify-content: space-between;
    width: 100%;
  }
`;

const SocialLink = styled.div`
  cursor: pointer;
  svg {
    path {
      transition: all ease 0.3s;
    }
  }
  &:hover {
    svg {
      transition: all linear 1s;
      path:nth-child(even) {
        fill: #b6413f;
      }
    }
  }

  height: 47px;

  @media (max-width: 592px) {
    height: 39px;
  }

  svg {
    height: 100%;
  }
`;
