import React from 'react';

const ResistIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="827" height="151" viewBox="0 0 827 151" fill="none" {...props}>
    <g clipPath="url(#clip0_13_1175)">
      <path d="M5.5 145.41C780.46 106.13 821 5.5 821 5.5" stroke="#B6413F" strokeWidth="11" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_13_1175">
        <rect width="826.51" height="150.91" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ResistIcon;
