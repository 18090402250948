import React, { useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { StyledButton } from 'src/components/Button/WalletConnectButton';

// eslint-disable-next-line react/display-name
const Banner = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#E2BE67">
      <BannerTitle>
        <h1>
          YOU ARE <br /> THE SINNER
        </h1>
      </BannerTitle>
      <p>
        The 7 Sins is a unique NFT cult that offers a one-of-a-kind experience to its members. This club is part of an expanding community of cool partners that share the same vision of having fun, being creative, and pushing boundaries. The sinners, who are the members of this club, have escaped and are out having a wild time. If you’re looking for a place where you can bring your bad (or good) self to the party, then the 7 Sins is your perfect home.
      </p>
      <StyledButton className="manifestoBtn">MANIFESTO</StyledButton>
      <FlowingBar className='first'>
        <img src="/images/bar1.png" />
        <img src="/images/bar1.png" />
        <img src="/images/bar1.png" />
      </FlowingBar>
      <FlowingBar className='second'>
        <img src="/images/bar2.png" />
        <img src="/images/bar2.png" />
        <img src="/images/bar2.png" />
      </FlowingBar>
      <FlowingBar className='third'>
        <img src="/images/bar3.png" />
        <img src="/images/bar3.png" />
        <img src="/images/bar3.png" />
      </FlowingBar>
      <FlowingBar className='fourth'>
        <img src="/images/bar4.png" />
        <img src="/images/bar4.png" />
        <img src="/images/bar4.png" />
      </FlowingBar>
      <FlowingBar className='fifth'>
        <img src="/images/bar5.png" />
        <img src="/images/bar5.png" />
        <img src="/images/bar5.png" />
      </FlowingBar>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 130px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;

  @media (max-width: 1280px) {
    padding-bottom: 130px;
    padding-left: 37px;
    padding-right: 37px;
  }

  @media (max-width: 744px) {
    padding-bottom: 74px;
  }

  @media (max-width: 592px) {
    padding-bottom: 71px;
    padding-left: 18px;
    padding-right: 18px;
  }

  p {
    font-family: 'Bergen Mono';
    font-style: normal;
    color: #1a1a1a;

    margin-top: 56px;
    margin-bottom: 52px;

    max-width: 930px;
    flex-direction: column;
    flex-shrink: 0;

    text-align: center;
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    
    @media(max-width: 744px) {
      margin-top: 46px;
      margin-bottom: 34px;

      width: 505px;
      font-size: 18px;
      line-height: 23px;
    }

    @media(max-width: 592px) {
      margin-top: 50px;
      width: 343px;
      font-size: 14px;
      line-height: 15px;
    }
  }

  .manifestoBtn {
    padding: 20px;
    font-size: 18px;
    margin-bottom: 113px;
    font-weight: 700;
    line-height: 0.5;

    @media (max-width: 1280px) {
      margin: 20px 0;
      margin-bottom: 45px;
    }

    @media (max-width: 592px) {
      margin: 25px 0;
      margin-bottom: 38px;
    }
  }

  h2 {
    font-family: 'Wolfskin';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #b6413f;
    font-size: 125px;
    line-height: 100px;
    margin: 71px 0;
    margin-bottom: 61px;
    max-width: 1280px;

    @media (max-width: 1439px) {
      font-size: 8.5vw;
      max-width: 90vw;
      line-height: 82%;
    }

    @media (max-width: 1280px) {
      font-size: 9.4vw;
      line-height: 85%;
      margin: 49px 0;
    }

    @media (max-width: 592px) {
      font-size: 13.2vw;
      line-height: 83%;
      margin: 56px 0;
      margin-bottom: 50px;
    }
  }

  .banner-text {
    font-size: 23px;
    line-height: 27px;
    max-width: 930px;

    @media (max-width: 1439px) {
      font-size: 1.6vw;
      line-height: 111%;
      max-width: 65vw;
    }

    @media (max-width: 1280px) {
      font-size: 2.4vw;
      line-height: 132%;
      max-width: 68.5vw;
    }

    @media (max-width: 592px) {
      font-size: 3.7vw;
      line-height: 106%;
      max-width: 89.5vw;
    }
  }
`;

const BannerTitle = styled.div`
  position: relative;

  h1 {
    font-family: 'Wolfskin';
    font-style: normal;
    font-weight: 400;
    font-size: 14.9vw;
    line-height: 80%;
    margin: 0;
    text-align: center;
    color: #B6413F;

    @media (max-width: 1439px) {
      font-size: 15vw;
      line-height: 75%;
    }

    @media (max-width: 1280px) {
      font-size: 19.4vw;
    }
  }
`;

const FlowingBar = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;

  &.first {
    -webkit-animation: flow 19s linear infinite;
    animation: flow 19s linear infinite;
  }
  &.second {
    -webkit-animation: flow-rev 20s linear infinite;
    animation: flow-rev 20s linear infinite;
  }
  &.third {
    -webkit-animation: flow 20s linear infinite;
    animation: flow 20s linear infinite;
  }
  &.fourth {
    -webkit-animation: flow-rev 21s linear infinite;
    animation: flow-rev 21s linear infinite;
  }
  &.fifth {
    -webkit-animation: flow 22s ease-out infinite;
    animation: flow 22s ease-out infinite;
  }

  img {
    height: 10.56vw;
    display: inline-block;

    @media (max-width: 1440px) {
      height: 152px;
    }

    @media (max-width: 744px) {
      height: 103px;
    }
  }

  @-webkit-keyframes flow {
    from { -webkit-transform: translateX(33.3%); transform: translateX(33.3%); }
    to { -webkit-transform: translateX(-33.3%); transform: translateX(-33.3%); }
  }

  @-webkit-keyframes flow-rev {
    from { -webkit-transform: translateX(-33.3%); transform: translateX(-33.3%); }
    to { -webkit-transform: translateX(33.3%); transform: translateX(33.3%); }
  }

  @keyframes flow {
    from { -webkit-transform: translateX(33.3%); transform: translateX(33.3%); }
    to { -webkit-transform: translateX(-33.3%); transform: translateX(-33.3%); }
  }

  @keyframes flow-rev {
    from { -webkit-transform: translateX(33.3%); transform: translateX(-33.3%); }
    to { -webkit-transform: translateX(-33.3%); transform: translateX(33.3%); }
  }
`;

export default Banner;
