/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Banner from './Banner';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import Supply from './Supply';
import Join from './Join';

const components = [Banner, Supply, Join];

export const Collection = () => {
  const GroupRef: any = useRef([]); // Create a ref for each component

  const onScroll = () => {
    const styles: any = GroupRef.current
      .map((group: any, i: number) => {
        const rect = group.getBoundingClientRect();

        return { group, rect };
      })
      .find((group: any) => group.rect.bottom >= window.innerHeight * 0.5);

    document.body.style.backgroundColor = `${styles.group.dataset.bgcolor}`;
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Container>
      {components.map((Component, i) => (
        <Component key={i} ref={(el: any) => (GroupRef.current[i] = el)} />
      ))}
    </Container>
  );
};

const Container = styled.div``;
