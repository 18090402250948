import React from 'react';

const Instagram = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="53" height="52" viewBox="0 0 41 40" fill="none" {...props}>
    <path d="M24.563 2.38867H12.1474C7.09158 2.38867 2.99658 6.46952 2.99658 11.5078V23.8805C2.99658 28.9188 7.09158 32.9997 12.1474 32.9997H24.563C29.6187 32.9997 33.7137 28.9188 33.7137 23.8805V11.5078C33.7137 6.46952 29.6187 2.38867 24.563 2.38867ZM30.6262 23.8805C30.6262 27.2177 27.9117 29.9228 24.563 29.9228H12.1474C8.79861 29.9228 6.08416 27.2177 6.08416 23.8805V11.5078C6.08416 8.17065 8.79861 5.46557 12.1474 5.46557H24.563C27.9117 5.46557 30.6168 8.17065 30.6262 11.5078V23.8805Z" fill="#1A1A1A" />
    <path d="M22.0444 0.464844H9.62881C4.57303 0.47414 0.487355 4.54569 0.478027 9.584V21.9567C0.478027 26.995 4.57303 31.0758 9.62881 31.0758H22.0444C27.1002 31.0758 31.1952 26.995 31.1952 21.9567V9.584C31.1952 4.54569 27.1002 0.464844 22.0444 0.464844Z" fill="white" />
    <path d="M9.62891 31.5406C4.33059 31.5406 0.0117188 27.2366 0.0117188 21.9566V9.58394C0.0117188 4.30394 4.33059 0 9.62891 0H22.0445C27.3428 0 31.6617 4.30394 31.6617 9.58394V21.9566C31.6617 27.2366 27.3428 31.5313 22.0445 31.5406H9.62891ZM9.62891 0.929577C4.84363 0.929577 0.944521 4.81521 0.944521 9.58394V21.9566C0.944521 26.7253 4.84363 30.611 9.62891 30.611H22.0445C26.8298 30.611 30.7289 26.7253 30.7289 21.9566V9.58394C30.7289 4.81521 26.8298 0.929577 22.0445 0.929577H9.62891Z" fill="#1A1A1A" />
    <path d="M15.8415 7.85521C11.4573 7.85521 7.89404 11.3969 7.89404 15.7752C7.89404 20.1535 11.448 23.6952 15.8415 23.6952C20.235 23.6952 23.789 20.1535 23.789 15.7752C23.789 11.4062 20.235 7.86451 15.8415 7.85521ZM15.8415 20.609C13.1644 20.609 10.9909 18.4431 10.9909 15.7752C10.9909 13.1073 13.1644 10.9414 15.8415 10.9414C18.5187 10.9414 20.6921 13.1073 20.6921 15.7752C20.6921 18.4431 18.5187 20.609 15.8415 20.609ZM23.7983 6.01465C24.8524 6.01465 25.7012 6.86056 25.7012 7.91099C25.7012 8.96141 24.8524 9.80733 23.7983 9.80733C22.7442 9.80733 21.8954 8.96141 21.8954 7.91099C21.8488 6.90704 22.623 6.06113 23.6304 6.01465C23.6864 6.01465 23.7423 6.01465 23.7983 6.01465Z" fill="white" />
    <path d="M15.8416 24.1507C11.2056 24.1507 7.42773 20.3952 7.42773 15.7659C7.42773 11.1366 11.1963 7.38107 15.8416 7.38107C20.4776 7.38107 24.2461 11.1459 24.2555 15.7659C24.2555 20.3859 20.487 24.1507 15.8416 24.1507ZM15.8416 8.31995C11.7186 8.31995 8.36054 11.6664 8.36054 15.7752C8.36054 19.8839 11.7186 23.2304 15.8416 23.2304C19.9646 23.2304 23.3227 19.8839 23.3227 15.7752C23.3227 11.6664 19.9646 8.32924 15.8416 8.31995ZM15.8416 21.0738C12.9126 21.0738 10.5246 18.694 10.5246 15.7752C10.5246 12.8563 12.9126 10.4766 15.8416 10.4766C18.7706 10.4766 21.1586 12.8563 21.1586 15.7752C21.1586 18.694 18.7706 21.0738 15.8416 21.0738ZM15.8416 20.1442C18.2576 20.1442 20.2258 18.1828 20.2258 15.7752C20.2258 13.3676 18.2576 11.4061 15.8416 11.4061C13.4256 11.4061 11.4574 13.3676 11.4574 15.7752C11.4574 18.1828 13.4256 20.1442 15.8416 20.1442ZM23.7984 10.2721C22.4925 10.2721 21.4291 9.21234 21.4291 7.91093C21.3731 6.67459 22.3526 5.60558 23.6118 5.5498C23.6771 5.5498 23.7424 5.5498 23.8171 5.5498C25.123 5.5498 26.177 6.60952 26.177 7.91093C26.177 9.21234 25.1137 10.2721 23.8077 10.2721H23.7984ZM23.7145 6.47938C23.7145 6.47938 23.6771 6.47938 23.6492 6.47938C22.9029 6.51657 22.3246 7.14868 22.3619 7.89234C22.3619 8.70107 23.0055 9.34248 23.7984 9.34248C24.5913 9.34248 25.2349 8.70107 25.2349 7.91093C25.2349 7.12079 24.5913 6.47938 23.7984 6.47938H23.7797C23.7797 6.47938 23.7424 6.47938 23.7145 6.47938Z" fill="#1A1A1A" />
  </svg>
);

export default Instagram;
