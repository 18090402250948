/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store';
import { CoinCardProps } from 'src/types';
import { getMainConfig, getProjectDetailsById } from 'src/utils/api';
import { startTimeCall, endTimeCall } from '../kingPad';

interface DataTypes {
  isLoading: boolean;
  data: CoinCardProps[];
}

const initialState: DataTypes = {
  isLoading: false,
  data: [
    {
      id: 0,
      chainId: 1,
      isKingStarter: false,
      status: 'Upcoming', // 0: all, 1: upcoming, 2: ongong, 3: ended
      token_address: '',
      coinImg: '',
      coinName: '',
      softCap: 0,
      hardCap: 0,
      time: 0,
      website: '',
      telegram: '',
      twitter: '',
      youtube: '',
      instagram: '',
      facebook: '',
      discord: ''
    }
  ]
};

export const coinDataSlice = createSlice({
  name: 'cardData',
  initialState,
  reducers: {
    setCardData: (state, action: PayloadAction<CoinCardProps[]>) => {
      state.data = { ...action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const CradleOfSinsProjectName = 'Cradle Of SinS';

export const coinCardData = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(coinDataSlice.actions.setLoading(true));
    const mainConfig = await getMainConfig();
    const coinCard = [];

    for (const config of mainConfig) {
      const project = await getProjectDetailsById(config.id);
      console.log(project);
      const now = Date.now();
      const kingpass_start = await startTimeCall();
      const kingpass_end = await endTimeCall();

      let status = 'Upcoming';
      let time = kingpass_start * 1000;

      if (kingpass_start * 1000 < now && kingpass_end * 1000 > now) {
        status = project.name === CradleOfSinsProjectName ? 'Ended' : 'Ongoing';
        time = project.name === CradleOfSinsProjectName ? now : kingpass_end * 1000;
      } else if (kingpass_end < now) {
        status = 'Ended';
        time = now;
      }

      coinCard.push({
        id: config.id,
        chainId: config.chain_id,
        isKingStarter: true,
        coinImg: project.logo,
        coinName: project.name,
        status,
        token_address: project.token_address,
        hardCap: project.hard_cap,
        softCap: project.soft_cap,
        time,
        website: project.website,
        twitter: project.twitter,
        telegram: project.telegram,
        youtube: project.youtube,
        facebook: project.facebook,
        instagram: project.instagram,
        discord: project.discord
      });
    }

    console.log(coinCard, '%%%%%%%%%%');

    dispatch(coinDataSlice.actions.setCardData(coinCard));
    dispatch(coinDataSlice.actions.setLoading(false));
  } catch (err) {
    dispatch(coinDataSlice.actions.setLoading(false));
  }
};

export default coinDataSlice.reducer;
