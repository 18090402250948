import styled from 'styled-components';
import { ALink } from 'src/components/ALink';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Discord, Opensea, Twitter, Instagram, Youtube, Logo, Tiktok } from 'src/config/images';
import { StyledButton } from 'src/components/Button/WalletConnectButton';

function validateEmail(email : string) {
  if (email === '') return true;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e : any) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  }

  return (
    <Container>
      <FooterContainer>
        <FooterTopContainer>
          <Title>
            <h4>Celebrate the light</h4>
            <h3>With the dark</h3>
          </Title>
          <FooterImage src="/images/footer.png" />
        </FooterTopContainer>
        <FooterLinks>
          <Link to="/collection">Collection</Link>
          <Link to="/mint">Mint</Link>
          <Link to="/news">News</Link>
          <Link to="/faq">Faq</Link>
          <Link to="/contact">Contact</Link>
        </FooterLinks>
        <FooterSubscribe>
          <Email>
            <div style={{marginBottom: '-20px'}}>
              <input
                type="text"
                placeholder="Your email address"
                value={email}
                onChange={handleEmailChange}
              />
              <EmailError style={{visibility: isValidEmail ? 'hidden' : 'visible'}}>
                Not a valid email address</EmailError>
            </div>
            <StyledButton>Subscribe</StyledButton>
            <DesktopSocial>
              <ALink link="/">Cookies</ALink>
              <ALink link="/">Privacy Policy</ALink>
              <ALink link="/">Terms & conditions</ALink>
            </DesktopSocial>
          </Email>
          <Social>
            <ALink link="/">
              <NavLink>
                <Twitter />
              </NavLink>
            </ALink>
            <ALink link="/">
              <NavLink>
                <Discord />
              </NavLink>
            </ALink>
            <ALink link="/">
              <NavLink>
                <Opensea />
              </NavLink>
            </ALink>
            <ALink link="/">
              <NavLink>
                <Youtube />
              </NavLink>
            </ALink>
            <ALink link="/">
              <NavLink>
                <Tiktok />
              </NavLink>
            </ALink>
            <ALink link="/">
              <NavLink>
                <Instagram />
              </NavLink>
            </ALink>
          </Social>
        </FooterSubscribe>
        <Terms>
          <ALink link="/">Cookies</ALink>
          <ALink link="/">Privacy Policy</ALink>
          <ALink link="/">Terms & conditions</ALink>
        </Terms>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #f4c1b9;
`;

const FooterContainer = styled.div`
  padding-top: 82px;
  padding-bottom: 158px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1280px;

  @media (max-width: 1280px) {
    padding-left: 33px;
    padding-right: 33px;
  }

  @media (max-width: 1280px) {
    padding: 62px 33px 77px 33px;
  }

  @media (max-width: 592px) {
    padding: 78px 20px 69px 20px;
  }
`;

const FooterTopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 3px solid #ffffff;

  @media (max-width: 592px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.div`
  width: fit-content;
  flex-shrink: 0;
  padding-bottom: 52px;

  h3 {
    font-weight: 400;
    font-family: 'Wolfskin';
    font-style: normal;
    text-transform: uppercase;
    color: #b6413f;
    font-size: 94px;
    line-height: 66px;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-family: 'Wolfskin';
    font-style: normal;
    text-transform: uppercase;
    color: #b6413f;
    font-size: 66px;
    margin: 0;
  }

  @media (max-width: 1439px) {
    padding-bottom: 3.5vw;
    h3 {
      font-size: 6.2vw;
      line-height: 100%;
    }

    h4 {
      font-size: 4.3vw;
      line-height: 100%;
    }
  }

  @media (max-width: 592px) {
    h3 {
      font-size: 13.2vw;
    }

    h4 {
      font-size: 9.3vw;
    }
  }
`;

const FooterImage = styled.img`
  max-width: 453px;
  width: 100%;

  @media (max-width: 1439px) {
    max-width: 30vw;
  }

  @media (max-width: 1280px) {
    max-width: 36.5vw;
  }

  @media (max-width: 592px) {
    max-width: 67.7vw;
  }
`;

const DesktopSocial = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  justify-content: center;
  gap: 6px;

  @media (max-width: 1280px) {
    display: none;
  }

  a {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 11px;
    color: #1a1a1a;
    text-transform: uppercase;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  border-bottom: 3px solid #ffffff;
  margin-bottom: 33px;

  a {
    font-family: 'Wolfskin';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
    color: #b6413f;
    line-height: 28px;
    text-decoration: none;
    margin: 0;
    &:hover {
      color: black;
    }

    @media (max-width: 1439px) {
      font-size: 3vw;
      line-height: 100%;
    }

    @media (max-width: 1280px) {
      font-size: 4vw;
      line-height: 100%;
    }

    @media (max-width: 592px) {
      font-size: 5.1vw;
      line-height: 100%;
    }
  }

  @media (max-width: 730px) {
    margin-bottom: 30px;
  }

  @media (max-width: 1440px) {
    padding-bottom: 2vw;
    padding-top: 2vw;
  }

  @media (max-width: 592px) {
    padding-bottom: 18px;
    padding-top: 18px;
  }
`;

const NavLink = styled.div`
  cursor: pointer;
  svg {
    path {
      transition: all ease 0.3s;
    }
  }
  &:hover {
    svg {
      transition: all linear 1s;
      path:nth-child(even) {
        fill: #b6413f;
      }
    }
  }
  
  height: 47px;

  @media (max-width: 592px) {
    height: 39px;
  }

  svg {
    height: 100%;
  }
`;

const FooterSubscribe = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1280px) {
    margin-bottom: 57px;
  }

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 28px;
  }
`;

const Email = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 10px;

  input {
    background-color: white;
    outline: none;
    background: #ffffff;
    border: 2px solid #1a1a1a;
    border-radius: 10px;
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #1a1a1a;
    max-width: 249px;
    width: 100%;
    padding: 20px;
    display: block;

    &::placeholder {
      color: #1a1a1a;
    }

    @media (max-width: 830px) {
      padding: 16px 20px;
      font-size: 15px;
      line-height: 15px;
    }

    @media (max-width: 592px) {
      font-size: 14px;
      padding: 13px 12px;
    }
  }

  button {
    background-color: white;
    outline: none;
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 0px;
    text-align: center;
    text-transform: uppercase;
    color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid var(--fakeblack, #1a1a1a);
    box-shadow: 6px 6px 0px 0px #000;

    @media (max-width: 1280px) {
      font-size: 15px;
    }

    @media (max-width: 592px) {
      font-size: 15px;
      padding: 16px 17px;
    }
  }
`;

const Social = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 33px;

  @media (max-width: 1280px) {
    gap: 5px;
  }

  @media (max-width: 720px) {
    margin-bottom: 31.5px;
    width: 100%;
    justify-content: space-between;
  }
`;

const Terms = styled.div`
  width: 100%;
  justify-content: space-around;
  display: none;

  @media (max-width: 1280px) {
    display: flex;
  }

  a {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #1a1a1a;

    @media (max-width: 592px) {
      font-size: 12px;
    }
  }
`;

const EmailError = styled.span`
  color: #b6413f;
  line-height: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: Bergen Mono;

  padding-left: 20px;
  @media (max-width: 428px) {
    padding-left: 5px;
    font-size: 13px;
  }
`;