import React, { useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ResistIcon } from 'src/config/images';
import { StyledButton } from 'src/components/Button/WalletConnectButton';
import HeroImage from 'src/config/images/HeroImage';

// eslint-disable-next-line react/display-name
const Banner = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#F9A256">
      <BannerTitle>
        <h1>
          INDULGE <br /> <span>OR RESIST?</span>
        </h1>
        <Resist>
          <ResistIcon />
        </Resist>
      </BannerTitle>
      <p>Be your own redemption, join the party</p>
      <StyledButton className="videoBtn">PLAY VIDEO</StyledButton>
      <HeroImageContainer>
        <HeroImage />
      </HeroImageContainer>
      <h2>Bring out the devil in you. Saint, sinner or anything in between.</h2>
      <p className="banner-text">
        We are the Siiners and we have an interesting story to share. It all started when we were chilling out with
        Dante in his circles. As we looked around, we noticed that some of our comrades were missing. We searched high
        and low but couldn’t find the guy with the fangs or his neighbor with purple horns, and then more of us
        disappeared. Suddenly, we found ourselves looking at each other around the waterside campfire with a beer in
        hand. <br /> <br /> <br />
        We were back up on earth, and we couldn’t believe it! How did we manage to escape the rings of hell? Were we
        finally free? These questions lingered in our minds, and we couldn’t wait to share our story with others.
      </p>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 6.4vw;

  @media (max-width: 1440px) {
    padding-left: 37px;
    padding-right: 37px;
  }

  @media (max-width: 744px) {
    padding-bottom: 14vw;
    padding-left: 18px;
    padding-right: 18px;
  }

  @media (max-width: 428px) {

  }

  p {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #1a1a1a;
    line-height: 100%;

    @media (max-width: 1280px) {
      font-size: 21px;
    }

    @media (max-width: 620px) {
      font-size: 16px;
      line-height: 22px;
      max-width: 224px;
      margin: 0 auto;
    }
  }

  .videoBtn {
    margin: 35px 0;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 13px;

    @media (max-width: 1280px) {
      font-size: 18px;
      padding: 20px 15px;
      margin: 20px 0;
      margin-bottom: 45px;
    }

    @media (max-width: 592px) {
      font-size: 15px;
      line-height: 11px;
      padding: 16px 17px;
      margin: 25px 0;
      margin-bottom: 38px;
    }
  }

  h2 {
    font-family: 'Wolfskin';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #b6413f;
    font-size: 8.7vw;
    line-height: 82%;
    margin: 71px 0;
    margin-bottom: 61px;
    max-width: 84vw;

    @media (max-width: 1439px) {
      font-size: 8.7vw;
      max-width: 90vw;
      line-height: 82%;
    }

    @media (max-width: 1280px) {
      font-size: 9.4vw;
      line-height: 85%;
      margin: 49px 0;
    }

    @media (max-width: 592px) {
      font-size: 13.2vw;
      line-height: 83%;
      margin: 56px 0;
      margin-bottom: 50px;
    }
  }

  .banner-text {
    font-size: 23px;
    line-height: 27px;
    max-width: 930px;

    @media (max-width: 1439px) {
      font-size: 1.6vw;
      line-height: 111%;
      max-width: 65vw;
    }

    @media (max-width: 1280px) {
      font-size: 2.4vw;
      line-height: 132%;
      max-width: 68.5vw;
    }

    @media (max-width: 592px) {
      font-size: 3.7vw;
      line-height: 106%;
      max-width: 89.5vw;
    }
  }
`;

const HeroImageContainer = styled.div`
  max-width: 1225px;
  width: 100%;
  height: fit-content;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const BannerTitle = styled.div`
  position: relative;
  margin-bottom: 25px;
  h1 {
    font-family: 'Wolfskin';
    font-style: normal;
    font-weight: 400;
    font-size: 260px;
    line-height: 190px;
    margin: 0;
    text-align: center;
    color: #1a1a1a;

    @media (max-width: 1439px) {
      font-size: 18vw;
      line-height: 75%;
    }

    @media (max-width: 1280px) {
      font-size: 19.9vw;
    }
  }

  @media (max-width: 592px) {
    margin-bottom: 28px;
  }
`;

const Resist = styled.div`
  position: absolute;
  right: 7.7%;
  bottom: 11%;
  width: 61%;

  svg {
    width: 100%;
    height: 100%;
  }

  /* @media (max-width: 1280px) {
    width: 216px;
    right: 8px;
    bottom: -38px;
  } */
`;

export default Banner;
