/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ImageButton from 'src/components/ImageButton';

const Explore = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#B6413F">
      <Title>
        LET’S EXPLORE <br /> YOUR SINS
      </Title>
      <ImageContainer>
        <div>
          <ImageButton image="/images/7sins2.png" title="GREED" />
          <ImageButton image="/images/7sins3.png" title="GLUTTONY" />
          <ImageButton image="/images/7sins4.png" title="WRATH" />
        </div>
        <div>
          <ImageButton image="/images/7sins5.png" title="ENVY" />
          <ImageButton image="/images/7sins6.png" title="PRIDE" />
          <ImageButton image="/images/7sins7.png" title="LUST" />
          <ImageButton image="/images/7sins8.png" title="SLOTH" />
        </div>
        <div>
          <ImageButton image="/images/7sins2.png" title="GREED" />
          <ImageButton image="/images/7sins3.png" title="GLUTTONY" />
          <ImageButton image="/images/7sins4.png" title="WRATH" />
          <ImageButton image="/images/7sins5.png" title="ENVY" />
          <ImageButton image="/images/7sins6.png" title="PRIDE" />
          <ImageButton image="/images/7sins7.png" title="LUST" />
          <ImageButton image="/images/7sins8.png" title="SLOTH" />
        </div>
      </ImageContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.16vw 10px;

  @media (max-width: 744px) {
    padding-top: 11.1vw;
    padding-bottom: 12.4vw;
  }

  @media (max-width: 428px) {
    padding-top: 17.4vw;
    padding-bottom: 19.6vw;
  }

  p {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #1a1a1a;
    line-height: 55px;
    margin-top: 90px;

    @media (max-width: 1630px) {
      font-size: 25px;
      line-height: 45px;
      margin-top: 60px;
    }

    @media (max-width: 1280px) {
      font-size: 11px;
      line-height: 20px;
      margin-top: 50px;
    }
  }
`;

const Title = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8vw;
  line-height: 80%;
  text-align: center;
  text-transform: uppercase;
  color: #f4c1b9;
  margin: 0;
  margin-bottom: 31px;

  @media (max-width: 1440px) {
    font-size: 12.8vw;
    line-height: 80%;
  }

  @media (max-width: 744px) {
    font-size: 13.2vw;
    line-height: 83%;
  }

  @media (max-width: 428px) {
    font-size: 15vw;
    line-height: 83%;
    margin-bottom: 40px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  & > div {
    & > div {
      @media (max-width: 1440px) {
        max-width: 16.5vw;

        button {
          font-size: 1.2vw;
          padding: 1.2vw;
        }
      }

      @media (max-width: 1280px) {
        max-width: 23.5vw;
        button {
          font-size: 2.2vw;
        }
      }

      @media (max-width: 592px) {
        max-width: 39.5vw;
        button {
          font-size: 3.2vw;
          padding: 2vw;
        }
      }
    }
    &:first-child {
      display: flex;
      justify-content: center;
      gap: 68px;

      @media (max-width: 1440px) {
        gap: 5vw;
      }

      @media (max-width: 1280px) {
        gap: 7vw;
      }

      @media (max-width: 592px) {
        display: none;
      }
    }

    &:nth-child(2) {
      display: flex;
      justify-content: center;
      gap: 79px;

      @media (max-width: 1440px) {
        gap: 6vw;
      }

      @media (max-width: 1280px) {
        flex-wrap: wrap;
        max-width: 82vw;
        margin: 0 auto;
      }

      @media (max-width: 592px) {
        display: none;
      }
    }

    &:nth-child(3) {
      display: none;

      @media (max-width: 592px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10vw;
      }
    }
  }
  max-width: 1280px;
`;

export default Explore;
