import { Suspense, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';

import { PUBLIC_ROUTES } from './config/routes';

import { Provider } from './Provider';
import { Layout } from './layouts/layout';

import { Home } from './pages/Home';
import { NotFoundPage } from './pages/404';

import { isEmpty } from './utils/validator';
import { initialize, setInitialized } from './web3/kingPad';
import { useAppDispatch } from './hooks';
import { Collection } from './pages/Collection';
import { Mint } from './pages/Mint';

function App() {
  const { isConnected } = useAccount();
  const dispatch = useAppDispatch();
  const provider = useProvider();
  const { data: signer } = useSigner();

  const { chain } = useNetwork();

  const chainId = chain?.id;

  useEffect(() => {
    if (isConnected) {
      (async () => {
        if (!isEmpty(provider) && !isEmpty(signer) && chainId !== undefined) {
          initialize(provider, signer, chainId)
            .then(() => {
              dispatch(setInitialized(true));
            })
            .catch((_err) => {
              dispatch(setInitialized(false));
            });
        }
      })();
    } else {
      dispatch(setInitialized(false));
    }
  }, [isConnected, signer]);

  return (
    <Suspense fallback={<>Loading</>}>
      <Router>
        <Provider>
          <Layout>
            <Routes>
              <Route path={PUBLIC_ROUTES.default} element={<Home />} />
              <Route path={PUBLIC_ROUTES.collection} element={<Collection />} />
              <Route path={PUBLIC_ROUTES.mint} element={<Mint />} />
              <Route path={PUBLIC_ROUTES.error404} element={<NotFoundPage />} />
              <Route path="*" element={<Navigate to={PUBLIC_ROUTES.error404} replace />} />
            </Routes>
          </Layout>
        </Provider>
      </Router>
    </Suspense>
  );
}

export default App;
