/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ImageButton2 from 'src/components/ImageButton/gif';

const Adopt = forwardRef((_props, ref: any) => {
  const navigate = useNavigate();

  return (
    <Container ref={ref} data-bgcolor="#AFC67B">
      <BannerTitle>Adopt a sinner</BannerTitle>
      <ImageButton2 title="Mint now" className="lg" onClick={() => navigate('/mint')} />
      <p>
        We welcome everyone with open arms, and we encourage you to be kind to the escaped little devils and the little
        devil within you. <br /> <br />
        Adopt and mint your very own sinner and join us on this dark journey.
      </p>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 277px;
  background-color: transparent;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 8vw;
  padding-bottom: 8.8vw;

  @media (max-width: 744px) {
    padding-top: 14.5vw;
    padding-bottom: 8.8vw;
  }

  @media (max-width: 428px) {
    padding-top: 25vw;
    padding-bottom: 25vw;  
  }

  p {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #000000;
    margin-top: 50px;
    font-size: 23px;
    line-height: 27px;
    max-width: 930px;

    @media (max-width: 1440px) {
      font-size: 1.6vw;
      line-height: 125%;
      max-width: 65vw;
      margin-top: 48px;
    }

    @media (max-width: 1280px) {
      font-size: 2.45vw;
      line-height: 125%;
      max-width: 69vw;
      margin-top: 48px;
    }

    @media (max-width: 592px) {
      font-size: 3.45vw;
      line-height: 116%;
      max-width: 88vw;
      margin-top: 38px;
    }
  }
`;

const BannerTitle = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 11.45vw;
  line-height: 80%;
  text-align: center;
  text-transform: uppercase;
  color: #814a7a;
  margin: 0;
  margin-bottom: 5px;

  @media (max-width: 1439px) {
    font-size: 11.4vw;
    line-height: 100%;
  }

  @media (max-width: 1280px) {
    font-size: 9.5vw;
  }

  @media (max-width: 744px) {
    font-size: 13.3vw;
  }

  @media (max-width: 428px) {
    font-size: 14.6vw;
  }
`;

export default Adopt;
