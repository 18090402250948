import styled from 'styled-components';
import { Container } from 'src/components/container/container';
import { ALink } from 'src/components/ALink';
import { StyledButton, WalletConnectButton } from 'src/components/Button/WalletConnectButton';
import { useNavigate, Link } from 'react-router-dom';
import { Logo } from 'src/config/images';

export const Header = ({ 
  handleMenuOpen 
} : {
  handleMenuOpen: () => void 
}) => {
  return (
    <HeaderContainer>
      <StyledButton className="mobile-header-btn" onClick={handleMenuOpen}>
        MENU</StyledButton>
      <HamburgerWrapper>
        <Hamburger onClick={handleMenuOpen}>
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>
      </HamburgerWrapper>
      <LogoButtonWrapper>
        <LogoButton to="/">
          <Logo />
        </LogoButton>
      </LogoButtonWrapper>
      <WalletConnectButton />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  margin: 34px 72px 76px 68px;
  z-index: 999;
  transition: all 0.5s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .mobile-header-btn {
    position: absolute;
    left: 0;
  }

  @media (max-width: 1280px) {
    margin-left: 31px;
    margin-right: 37px;
    margin-bottom: 70px;
  }

  @media (max-width: 592px) {
    margin: 21px 29px 48px 25px;

    .mobile-header-btn {
      display: none;
    }
  }
`;

const LogoButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const HamburgerWrapper = styled.div`
  display: none;

  @media (max-width: 592px) {
    display: flex;
    width: 97.8px;
    position: absolute;
  }
`;

const Hamburger = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  width: 30px;
  flex-direction: column;
  display: flex;

  @media (max-width: 592px) {
    cursor: pointer;
    margin-left: 15px;
  }

  span {
    border: 4px solid #1a1a1a;
    height: 0;
    width: 100%;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }
`;

const LogoButton = styled(Link)`
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  width: 83.97px;
  height: 121px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 592px) {
    width: 46px;
    height: 66px;
  }
`;
