import { configureStore } from '@reduxjs/toolkit';
import kingPadSlice from '../web3/kingPad';
import cardDataSlice from '../web3/cardData';

export const store = configureStore({
  reducer: {
    kingPad: kingPadSlice,
    cardData: cardDataSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
