/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Contract, ethers } from 'ethers';
import kingPadABI from '../../ABIs/kingpad_abi.json';
import vestingABI from '../../ABIs/vesting_abi.json';
import { AppDispatch } from 'src/store';

const defaultProvider = new ethers.providers.JsonRpcProvider('https://customrpc.kingfinance.co/eth');
let provider: any = null;
let signer: any = null;

let kingPad: Contract = new ethers.Contract('0xDB8E00Ef74951874cEB59584483C9490fAE3747b', kingPadABI, defaultProvider);
let vesting: Contract = new ethers.Contract('0xF66307319D3f509a928d6BEb5ec980d03340f8fe', vestingABI, defaultProvider);

interface ProviderInfos {
  [key: number]: {
    rpcUrl: string;
    token: string;
    kingPass: string;
    kingPad: string;
    vesting: string;
  };
}

const providerInfos: ProviderInfos = {
  1: {
    rpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/GAG2amJoAjnroZO0qYTLJXRbyO1PZnrg',
    token: '0xA5b96401cA969B0FDe65eF193d8E53B55b94742E',
    kingPass: '0xE114332852628e7bC13338c8eDbFf8C998CaCb89',
    kingPad: '0xe86cF4F82249221FC4054133fDDdC56ccC437912',
    vesting: '0xF66307319D3f509a928d6BEb5ec980d03340f8fe'
  },
  31337: {
    rpcUrl: 'https://customrpc.kingfinance.co/eth',
    token: '0xA5b96401cA969B0FDe65eF193d8E53B55b94742E',
    kingPass: '0xE114332852628e7bC13338c8eDbFf8C998CaCb89',
    kingPad: '0xDB8E00Ef74951874cEB59584483C9490fAE3747b',
    vesting: '0xF66307319D3f509a928d6BEb5ec980d03340f8fe'
  }
};

export const initialize = async (provider_: any, signer_: any, chainId: number) => {
  const data = providerInfos[chainId];
  console.log(chainId, data);
  kingPad = new ethers.Contract(data.kingPad, kingPadABI, signer_);
  vesting = new ethers.Contract(data.vesting, vestingABI, signer_);
  provider = provider_;
  signer = signer_;
};

interface KingPadState {
  initialized: boolean;
  startTime: number;
  endTime: number;
  // amount: number;
  startWhitelistTime: number;
  endWhitelistTime: number;
  tokensRatioPerETH: number;
  softCap: number;
  hardCap: number;
  totalRaisedETH: number;
  totalToClaim: number;
  totalTokensInLiquidity: number;
  liquidityRatio: number;
  liquidityUnlockIn: number;
  vestingActive: boolean;
  token: string;
  dexRouter: string;
  kingPass: string;
  vesting: string;
  saleState: number;
  currentTime: number;
}

const initialState: KingPadState = {
  initialized: false,
  startTime: 0,
  endTime: 0,
  // amount: 0,
  startWhitelistTime: 0,
  endWhitelistTime: 0,
  tokensRatioPerETH: 0,
  softCap: 0,
  hardCap: 0,
  totalRaisedETH: 0,
  totalToClaim: 0,
  totalTokensInLiquidity: 0,
  liquidityRatio: 0,
  liquidityUnlockIn: 0,
  vestingActive: false,
  token: '',
  dexRouter: '',
  kingPass: '',
  vesting: '',
  saleState: 0,
  currentTime: 0
};

type Fields = {
  [K in keyof KingPadState]: {
    field: K;
    value: KingPadState[K];
  };
};

export const kingPadSlice = createSlice({
  name: 'kingPad',
  initialState,
  reducers: {
    setField: (state, action: PayloadAction<Fields[keyof Fields]>) => {
      const { field, value } = action.payload;
      (state[field] as unknown) = value;
    }
  }
});

const getStartTime = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.startTime();
  dispatch(kingPadSlice.actions.setField({ field: 'startTime', value: Number(temp?._hex ?? 0) }));
};

export const startTimeCall = async () => {
  const temp = await kingPad.startTime();
  return Number(temp?._hex ?? 0);
};

export const endTimeCall = async () => {
  const temp = await kingPad.endTime();
  return Number(temp?._hex ?? 0);
};

const setInitialized = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(kingPadSlice.actions.setField({ field: 'initialized', value }));
};

const getEndTime = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.startTime();
  dispatch(kingPadSlice.actions.setField({ field: 'endTime', value: Number(temp?._hex ?? 0) }));
};

const getStartWhitelistTime = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.startWhitelistTime();
  dispatch(kingPadSlice.actions.setField({ field: 'startWhitelistTime', value: Number(temp?._hex ?? 0) }));
};

const getEndWhitelistTime = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.endWhitelistTime();
  dispatch(kingPadSlice.actions.setField({ field: 'endWhitelistTime', value: Number(temp?._hex ?? 0) }));
};

const getTokensRatioPerETH = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.tokensRatioPerETH();
  dispatch(
    kingPadSlice.actions.setField({
      field: 'tokensRatioPerETH',
      value: parseFloat(ethers.utils.formatEther((temp ?? 0).toString()))
    })
  );
};

const getSoftCap = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.softCap();
  dispatch(kingPadSlice.actions.setField({ field: 'softCap', value: Number(temp?._hex ?? 0) }));
};

const getHardCap = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.hardCap();
  dispatch(kingPadSlice.actions.setField({ field: 'hardCap', value: Number(temp?._hex ?? 0) }));
};

const getTotalRaisedETH = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.totalRaisedETH();
  dispatch(
    kingPadSlice.actions.setField({
      field: 'totalRaisedETH',
      value: parseFloat(ethers.utils.formatEther((temp ?? 0).toString()))
    })
  );
};

const getTotalToClaim = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.totalToClaim();
  dispatch(
    kingPadSlice.actions.setField({
      field: 'totalToClaim',
      value: parseFloat(ethers.utils.formatEther((temp ?? 0).toString()))
    })
  );
};

const getTotalTokensInLiquidity = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.totalTokensInLiquidity();
  dispatch(
    kingPadSlice.actions.setField({
      field: 'totalTokensInLiquidity',
      value: parseFloat(ethers.utils.formatEther((temp ?? 0).toString()))
    })
  );
};

const getLiquidityRatio = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.liquidityRatio();
  dispatch(kingPadSlice.actions.setField({ field: 'liquidityRatio', value: Number(temp?._hex ?? 0) }));
};

const getLiquidityUnlockIn = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.liquidityUnlockIn();
  dispatch(kingPadSlice.actions.setField({ field: 'liquidityUnlockIn', value: Number(temp?._hex ?? 0) }));
};

const getVestingActive = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.vestingActive();
  dispatch(kingPadSlice.actions.setField({ field: 'vestingActive', value: temp }));
};

const getToken = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.token();
  dispatch(kingPadSlice.actions.setField({ field: 'token', value: temp }));
};

const getDexRouter = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.dexRouter();
  dispatch(kingPadSlice.actions.setField({ field: 'dexRouter', value: temp }));
};

const getKingPass = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.kingPass();
  dispatch(kingPadSlice.actions.setField({ field: 'kingPass', value: temp }));
};

const getVesting = () => async (dispatch: AppDispatch) => {
  const temp = await kingPad.vesting();
  dispatch(kingPadSlice.actions.setField({ field: 'vesting', value: temp }));
};

const deposit = async (amount_: string, callback?: () => Promise<void>) => {
  const amount = amount_;
  await kingPad.deposit({ value: amount });
  if (callback != null) {
    setTimeout(() => {
      callback().catch((error) => {
        console.error('Error in callback:', error);
      });
    }, 500);
  }
};

const claim = async (callback?: () => Promise<void>) => {
  const tx = await kingPad.claim();
  await tx.wait();
  if (callback != null) {
    setTimeout(() => {
      callback().catch((error) => {
        console.error('Error in callback:', error);
      });
    }, 500);
  }
};

const claimFailedPresale = async (callback?: () => Promise<void>) => {
  const tx = await kingPad.claimFailedPresale();
  await tx.wait();
  if (callback != null) {
    setTimeout(() => {
      callback().catch((error) => {
        console.error('Error in callback:', error);
      });
    }, 500);
  }
};

const finalize = async () => {
  const tx = await kingPad.finalize();
  await tx.wait();
};

export {
  getStartTime,
  getEndTime,
  getStartWhitelistTime,
  getEndWhitelistTime,
  getTokensRatioPerETH,
  getSoftCap,
  getHardCap,
  getTotalRaisedETH,
  getTotalToClaim,
  getTotalTokensInLiquidity,
  getLiquidityRatio,
  getLiquidityUnlockIn,
  getVestingActive,
  getToken,
  getDexRouter,
  getKingPass,
  getVesting,
  deposit,
  claim,
  finalize,
  claimFailedPresale,
  setInitialized
};

export default kingPadSlice.reducer;
