import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Header } from './header';
import { Footer } from './footer';
import { Menu } from '../pages/Menu';
import { useState } from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    isMenuOpen ? 
    <Menu handleMenuClose={() => setMenuOpen(false) } /> :
    <MainContainer>
      <Header
        handleMenuOpen={() => setMenuOpen(true)}
      />
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
      />
      <Footer />
    </MainContainer>
  );
};

const MainContainer = styled.div``;
