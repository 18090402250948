import styled from 'styled-components';
import { StyledButton } from 'src/components/Button/WalletConnectButton';
import { useEffect } from 'react';

export const Mint = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#B6413F';
  }, []);

  return (
    <Container data-bgcolor="#B6413F">
      <Title>Mint your<br/>sinner</Title>
      <Content>
        <MintContainer>
          <Label1>2,456 / 9,731</Label1>
          <StyledButton className='btn-mint'>Mint now</StyledButton>
          <EthContainer>
            <img src="/images/eth.svg" />
            <label>0.05 ETH</label>
          </EthContainer>
        </MintContainer>
        <img src="/images/5881.png" className="box" />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 118px;
  color: black;
`;

const Title = styled.div`
  color: #F4C1B9;
  font-family: Wolfskin;

  @media(min-width: 744px) {
    font-size: 146px;
    line-height: 110px;
    margin-bottom: 48px;
  }

  @media(max-width: 743px) {
    font-size: 17.5vw;
    line-height: 14.5vw;
    margin-bottom: 26px;
  }

  @media (max-width: 428px) {
    font-size: 80px;
    line-height: 60px;
  }

  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`;

const Content = styled.div`
  @media (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 699px) {
    text-align: center;
  }

  img.box {
    @media (min-width: 700px) {
      margin-right: auto;
      marginBottom: -7px;
    }

    @media (max-width: 699px) {
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

const MintContainer = styled.div`
  width: 298px;
  height: 292px;
  background: #F9A256;
  padding: 56px 50px;
  align-items: center;
  gap: 32px;
  border-radius: 12px;

  @media (min-width: 700px) {
    margin-right: 15px;
    margin-left: auto;
  }
  
  @media (max-width: 699px) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
  }

  flex-direction: column;
  border: 4px solid #1a1a1a;
  border-radius: 16px;
  box-shadow: 7px 7px 0 black;

  .btn-mint {
    border: 3px solid;
    box-shadow: 5px 5px 0 black;
    font-size: 27px;
    font-weight: 500;
    padding: 26px;
  }
`;

const Label1 = styled.label`
  font-size: 24px;
  line-height: 11px;
  font-weight: 700;
  font-family: 'Bergen Mono';
  display: block;
  margin-bottom: 32px;
`;

const EthContainer = styled.div`
  display: flex;
  margin-top: 32px;

  img {
    margin-left: auto;
  }

  label {
    font-size: 20px;
    font-family: 'Bergen Mono';
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    margin-right: auto;
  }
`;
