import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ImageButton2 = ({
  title,
  className,
  onClick
}: {
  title: string;
  className?: string;
  onClick?: () => void;
}) => {
  const [imgIndex, setIndex] = useState(0);
  const intervalIdRef = useRef<number>(1);

  useEffect(() => {
    intervalIdRef.current = window.setInterval(() => {
        setIndex(imgIndex => (imgIndex + 1) % 18);
    }, 250);
    return () => {
        window.clearInterval(intervalIdRef.current);
    }
  }, []);

  return (
    <Container className={className}>
      <img src={`/images/home/7S_${imgIndex}.png`} alt="" />
      <StyledButton className={onClick != null ? 'clickable' : ''}>{title}</StyledButton>
    </Container>
  );
};

const StyledButton = styled.button`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 13px;
  text-align: center;
  color: #1a1a1a;
  text-transform: uppercase;
  padding: 20px;
  border: 2px solid #1a1a1a;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  appearance: none;

  &.clickable {
    font-size: 18px;
    line-height: 13px;
    color: #1a1a1a;
    text-transform: uppercase;
    padding: 20px;
    border: 2px solid #1a1a1a;
    box-shadow: 6px 6px 0px #000000;
    border-radius: 10px;
    cursor: pointer;

    transition: all 0.3s ease;

    &:hover {
      color: white;
      background: #b6413f;
    }

    @media (max-width: 1280px) {
      font-size: 18px;
      padding: 20px 15px;
    }

    @media (max-width: 592px) {
      font-size: 15px;
      line-height: 11px;
      padding: 16px 17px;
    }
  }
`;

const Container = styled.div`
  max-width: 237px;
  margin-top: -27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.lg {
    max-width: 392px;
  }

  img {
    width: 100%;

    @media (max-width: 428px) {
      width: 85%;
    }
  }

  button {
    margin-top: -30px;
  }

  @media (max-width: 1280px) {
    max-width: 188px;
    &.lg {
      max-width: 372px;
    }
  }

  @media (max-width: 592px) {
    max-width: 155px;
  }
`;

export default ImageButton2;
