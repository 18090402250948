import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ImageButton from 'src/components/ImageButton';

interface InfoType {
  type?: string;
  name: string;
  status: string;
}

export const InfoItem = ({ type, name, status }: InfoType) => {
  return (
    <InfoItemWrapper>
      <div>
        {type != null && <Type>{type} | </Type>}
        <InfoName>{name}</InfoName>
      </div>
      <InfoStatus>{status}</InfoStatus>
    </InfoItemWrapper>
  );
};

const InfoItemWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  & > div {
    &:first-child {
      display: flex;
    }
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Type = styled.h4`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  margin: 0;
  padding-right: 15px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 592px) {
    font-size: 14px;
  }
`;

const InfoName = styled.h4`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  font-weight: 600;
  margin: 0%;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 592px) {
    font-size: 3.65vw;
  }
`;

const InfoStatus = styled.div`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
  background: #1a1a1a;
  border: 3px solid #1a1a1a;
  padding: 12px 14px;
  border-radius: 10px;
  margin: 0 15px;
  white-space: nowrap;

  @media (max-width: 744px) {
    font-size: 14px;
    padding: 8px 6px;
  }

  @media (max-width: 1280px) {
    margin: 0;
    margin-top: 5px;
  }

  @media (max-width: 592px) {
    font-size: 3.65vw;
  }
`;

// eslint-disable-next-line react/display-name
const Celebrate = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#814A7A">
      <Top>
        <TitleWrapper>
          <Title>celebrate creativity, fun, and exploration</Title>
        </TitleWrapper>
        <InfoTableWrapper>
          <InfoTable>
            <InfoItem type="Digital" name="Partnership" status="exploring" />
            <InfoItem type="Digital" name="Metaverse" status="Stand by" />
            <InfoItem type="Digital" name="3D characters" status="In progress" />
          </InfoTable>
          <InfoTable>
            <InfoItem type="Culture" name="Events" status="exploring" />
            <InfoItem type="Culture" name="Music" status="In progress" />
            <InfoItem type="Culture" name="Dao" status="Stand by" />
          </InfoTable>
          <InfoTable>
            <InfoItem type="Physical" name="Partnership" status="exploring" />
            <InfoItem type="Physical" name="Toys" status="exploring" />
            <InfoItem type="Physical" name="Consumables" status="In progress" />
            <InfoItem type="Physical" name="Gallery" status="Exploring" />
          </InfoTable>
          <InfoTable>
            <InfoItem type="Culture" name="Events" status="exploring" />
            <InfoItem type="Culture" name="Music" status="In progress" />
            <InfoItem type="Culture" name="Dao" status="Stand by" />
          </InfoTable>
        </InfoTableWrapper>
      </Top>
      <p>
        Our vision is to create a thriving community that celebrates creativity, fun, and exploration. Our mission is to
        follow a development roadmap that includes creating toys, wearable merchandise, and forging partnerships with
        food outlets and all things tempting.
      </p>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8vw 20px 9.4vw;

  @media (max-width: 744px) {
    padding: 20vw 20px;
  }

  @media (max-width: 428px) {
    padding: 20vw 20px;
  }

  p {
    max-width: 738px;
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    text-align: center;
    color: #fff9b5;
    margin-top: 100px;

    @media (max-width: 1439px) {
      font-size: 1.6vw;
      line-height: 117%;
      max-width: 51vw;
    }

    @media (max-width: 1280px) {
      font-size: 2.4vw;
      line-height: 141%;
      max-width: 71vw;
      margin-top: 63px;
    }

    @media (max-width: 592px) {
      font-size: 3.5vw;
      line-height: 107%;
      max-width: 80vw;
      margin-top: 58px;
    }
  }
`;

const InfoTableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  max-width: 1280px;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div``;

const InfoTable = styled.div`
  padding: 50px 40px;
  background-color: #fff9b5;
  border: 3px solid #000000;
  box-shadow: 6px 6px 0px #000000;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  height: fit-content;

  @media (max-width: 1024px) {
    padding: 37px 40px;
  }

  @media (max-width: 592px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 8.7vw;
  line-height: 82%;
  text-align: center;
  text-transform: uppercase;
  color: #fff9b5;
  margin: 0 auto;
  max-width: 1280px;
  margin-bottom: 7.8vw;

  @media (max-width: 744px) {
    font-size: 9.4vw;
    line-height: 82%;
    max-width: 90vw;
    margin-bottom: 59px;
  }

  @media (max-width: 428px) {
    font-size: 13vw;
    line-height: 82%;
    max-width: 81vw;
    margin-bottom: 57px;
  }
`;

export default Celebrate;
