/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import styled from 'styled-components';
import { StyledButton } from 'src/components/Button/WalletConnectButton';

const Join = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#F9A256">
      <BannerTitle>JOIN THE SINNERS<br />COMMUNITY</BannerTitle>
      <DiscordIcon src="/images/discordicon.svg" alt="discord icon" />
      <StyledButton className='btn-join'>Join now</StyledButton>
      <DiscordImg src="/images/join-discord.png" />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 277px;
  background-color: transparent;
  padding: 7.8vw 10px 9vw;

  @media (max-width: 744px) {
    padding-top: 12vw;
  }

  p {
    font-family: 'Bergen Mono';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #000000;
    margin-top: 50px;
    font-size: 23px;
    line-height: 27px;
    max-width: 930px;

    @media (max-width: 1440px) {
      font-size: 1.6vw;
      line-height: 125%;
      max-width: 65vw;
      margin-top: 48px;
    }

    @media (max-width: 1280px) {
      font-size: 2.45vw;
      line-height: 125%;
      max-width: 69vw;
      margin-top: 48px;
    }

    @media (max-width: 592px) {
      font-size: 3.45vw;
      line-height: 116%;
      max-width: 88vw;
      margin-top: 38px;
    }
  }

  .btn-join {
    margin-top: 10px;
    padding: 20px;
    font-size: 18px;
    border: 2px solid #1a1a1a;
  }
`;

const BannerTitle = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 11.5vw;
  line-height: 84.3%;
  text-align: center;
  text-transform: uppercase;
  color: #814A7A;
  margin: 0;
  margin-bottom: 5px;

  @media (max-width: 744px) {
    font-size: 12.1vw;
  }

  @media (max-width: 428px) {
    font-size: 18vw;
  }
`;

const DiscordIcon = styled.img`
  height: 7.85vw;
  min-height: 77px;
  margin-top: 7px;

  @media (max-width: 744px) {
    height: 77px;
  }
`;

const DiscordImg = styled.img`
  width: 82vw;

  @media (min-width: 1440px) {
    margin-top: -105px;
  }

  @media (max-width: 744px) {
    width: 97vw;
    min-width: 575px;
    margin-top: -22px;
  }

  @media (max-width: 428px) {
    margin-top: 0;
  }
`;

export default Join;
