import React from 'react';

const YoutubeIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="60" height="43" viewBox="0 0 46 33" fill="none" {...props}>
    <path
      d="M41.1986 7.29934C40.7516 5.64347 39.4609 4.35088 37.8076 3.90312C34.8215 3.10052 22.8178 3.10052 22.8178 3.10052C22.8178 3.10052 10.8227 3.10052 7.82809 3.90312C6.17474 4.35088 4.88412 5.64347 4.43705 7.29934C3.63568 10.2985 3.63568 16.5503 3.63568 16.5503C3.63568 16.5503 3.63568 22.802 4.43705 25.8012C4.88412 27.457 6.17474 28.7496 7.82809 29.1974C10.8227 30 22.8178 30 22.8178 30C22.8178 30 34.813 30 37.8076 29.1974C39.4609 28.7496 40.7516 27.457 41.1986 25.8012C42 22.802 42 16.5503 42 16.5503C42 16.5503 42 10.2985 41.1986 7.29934ZM18.9882 22.312V10.7801L28.9588 16.5418L18.9882 22.3036V22.312Z"
      fill="#1A1A1A"
    />
    <path
      d="M37.9847 4.62124C37.5376 2.96537 36.247 1.67278 34.5937 1.22502C31.6075 0.422424 19.6039 0.422424 19.6039 0.422424C19.6039 0.422424 7.60874 0.422424 4.61416 1.22502C2.96082 1.67278 1.6702 2.96537 1.22312 4.62124C0.421753 7.6204 0.421753 13.8722 0.421753 13.8722C0.421753 13.8722 0.421753 20.1239 1.22312 23.1231C1.6702 24.7789 2.96082 26.0715 4.61416 26.5193C7.60874 27.3219 19.6039 27.3219 19.6039 27.3219C19.6039 27.3219 31.5991 27.3219 34.5937 26.5193C36.247 26.0715 37.5376 24.7789 37.9847 23.1231C38.7861 20.1239 38.7861 13.8722 38.7861 13.8722C38.7861 13.8722 38.7861 7.6204 37.9847 4.62124ZM15.7742 19.6339V8.10195L25.7449 13.8637L15.7742 19.6255V19.6339Z"
      fill="white"
    />
    <path
      d="M19.6039 27.7359C19.1147 27.7359 7.5244 27.7274 4.50452 26.9164C2.71621 26.4348 1.29906 25.0155 0.818237 23.216C0.00843547 20.2168 0 14.1256 0 13.8721C0 13.6187 0.00843547 7.52746 0.818237 4.51141C1.29906 2.72036 2.71621 1.30104 4.50452 0.819488C7.5244 0.00844837 19.1062 0 19.6039 0C20.1016 0 31.6919 0.00844837 34.7034 0.819488C36.5001 1.30104 37.9088 2.72036 38.3981 4.51141C39.2079 7.52746 39.2163 13.6103 39.2163 13.8721C39.2163 14.134 39.2079 20.2168 38.3981 23.2329C37.9173 25.0324 36.5001 26.4433 34.7034 26.9333C31.6919 27.7443 20.1016 27.7527 19.6039 27.7527V27.7359ZM19.6039 0.844832C19.4858 0.844832 7.63406 0.853281 4.72384 1.63053C3.22233 2.03605 2.03294 3.22726 1.62804 4.73106C0.851978 7.63729 0.843543 13.8046 0.843543 13.8721C0.843543 13.9397 0.851978 20.107 1.62804 23.0132C2.03294 24.517 3.22233 25.7083 4.72384 26.1138C7.63406 26.891 19.4858 26.8995 19.6039 26.8995C19.722 26.8995 31.5822 26.891 34.484 26.1138C35.9855 25.7083 37.1749 24.517 37.5798 23.0132C38.3559 20.107 38.3643 13.9397 38.3643 13.8721C38.3643 13.8046 38.3559 7.64573 37.5798 4.73106C37.1749 3.22726 35.9855 2.03605 34.484 1.63053C31.5822 0.853281 19.722 0.844832 19.6039 0.844832ZM15.3525 20.3605V7.36694L26.5885 13.8637L15.3525 20.3605ZM16.196 8.8285V18.8905L24.9014 13.8553L16.196 8.82005V8.8285Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default YoutubeIcon;
