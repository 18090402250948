import React, { forwardRef, useEffect, useRef } from 'react';
import TeamMember from 'src/components/TeamMember';
import styled from 'styled-components';

// eslint-disable-next-line react/display-name
const Team = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#F9A256">
      <Title>Meet the team</Title>
      <TeamItemWrapper>
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
        <TeamMember name="Mark Loft" role="Marketing" src="/images/974.png" />
      </TeamItemWrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.4vw 0px 7vw;

  @media (max-width: 744px) {
    padding-top: 4.7vw;
    padding-bottom: 5.6vw;
  }

  @media (max-width: 428vw) {
    padding-top: 9.6vw;
    padding-bottom: 14vw;
  }
`;

const TeamItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 68px;
  column-gap: 50px;

  @media (max-width: 1439px) {
    row-gap: 4vw;
    column-gap: 4vw;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        width: 18vw;
        height: 18vw;
      }
    }
  }

  @media (max-width: 1280px) {
    row-gap: 3.3vw;
    column-gap: 1.8vw;

    & > div {
      & > div {
        width: 21.24vw;
        height: 21.24vw;
      }
    }
  }

  @media (max-width: 700px) {
    row-gap: 20px;
    column-gap: 27px;
    grid-template-columns: repeat(2, 1fr);
    & > div {
      & > div {
        width: 158px;
        height: 158px;
      }
    }
  }
`;

const Title = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 8.67vw;
  text-align: center;
  text-transform: uppercase;
  color: #fff9b5;
  line-height: 80%;
  margin: 0 auto;
  margin-bottom: 85px;

  @media (max-width: 1439px) {
    font-size: 8.7vw;
    line-height: 100%;
  }

  @media (max-width: 1280px) {
    font-size: 9.5vw;
    line-height: 100%;
    margin-bottom: 56px;
  }

  @media (max-width: 592px) {
    font-size: 13vw;
    line-height: 100%;
    margin-bottom: 39px;
  }
`;

export default Team;
