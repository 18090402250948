import styled from 'styled-components';

const TeamMember = ({
  src,
  name,
  role,
  className
}: {
  src?: string;
  name: string;
  role: string;
  className?: string;
}) => {
  return (
    <Container className={className}>
      <ImageWrapper>
        <img src={src} alt="" />
      </ImageWrapper>
      <Name>{name}</Name>
      <Role>{role}</Role>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  background-color: white;
  border: 3px solid #1a1a1a;
  border-radius: 10px;
  box-shadow: 6px 6px 0 black;
  width: 261px;
  height: 261px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 7px;
  }
`;

const Name = styled.h4`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  color: #1a1a1a;
  margin-top: 16px;
  margin-bottom: 6px;
  width: 100%;
  text-align: left;

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 17px;
  }
`;

const Role = styled.h4`
  font-family: 'Bergen Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;
  color: #1a1a1a;
  margin: 0;
  width: 100%;
  text-align: left;

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

export default TeamMember;
