/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Supply = forwardRef((_props, ref: any) => {
  return (
    <Container ref={ref} data-bgcolor="#B6413F">
      <Title className='mobile'>SINNER SUPPLY</Title>
      <ImageContent>
        <ImageContainer>
          <img src="/images/9.png" alt="9" />
          <div><label className='label-9'>Are Dante's circles of hell (Inferno)</label></div>
        </ImageContainer>
        <ImageContainer>
          <img src="/images/7.png" alt="7" />
          <div><label className='label-7'>Are the days of Dante's journey in the afterlife</label></div>
        </ImageContainer>
        <ImageContainer>
          <img src="/images/3.png" alt="3" />
          <div><label className='label-3'>Are the numbers of cantiche in the The Divine Comedy</label></div>
        </ImageContainer>
        <ImageContainer>
          <img src="/images/1.png" alt="1" />
          <div><label className='label-1'>It represents the origin of all things</label></div>
        </ImageContainer>
      </ImageContent>
      <Title className='desktop'>SINNER SUPPLY</Title>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8.3vw;
  padding-bottom: 9.3vw;

  @media (max-width: 744px) {
    padding-top: 13vw;
    padding-bottom: 11vw;
  }

  @media (max-width: 592px) {
    padding-top: 14.5vw;
    padding-bottom: 14.5vw;
  }

  @media (max-width: 390px) {
    padding-top: 17vw;
    padding-bottom: 17vw;
  }
`;

const Title = styled.h1`
  font-family: 'Wolfskin';
  font-style: normal;
  font-weight: 400;
  font-size: 14.1vw;
  line-height: 80%;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 31px;
  color: #AFC67B;

  &.mobile {
    display: none;
  }

  @media (max-width: 744px) {
    font-size: 14vw;
  }

  @media (max-width: 428px) {
    font-size: 26.6vw;
    line-height: 80%;
    
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
      margin-bottom: 31px;
    }
  }
`;

const ImageContent = styled.div`
  padding: 0 9.7vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5.5vw;

  @media(max-width: 744px) {
    padding: 0 7.6vw;
  }

  @media(max-width: 428px) {
    padding: 0 38px;
    display: block;
  }
    
  img {
    height: 21vw;
    min-height: 177px;
  }
`;

const ImageContainer = styled.div`
  position: relative; 
  display: inline-block;
  text-align: center;

  @media (max-width: 428px) {
    display: block;
  }

  div {
    display: flex;
    justify-content: center;

    label {
      text-align: center;
      font-size: 23px;
      font-family: 'Bergen Mono';
      font-weight: normal;
      line-height: 27px;
      margin-top: 37px;
      display: block;

      &.label-9 {
        width: 210px;
      }
      &.label-7 {
        width: 250px;
      }
      &.label-3 {
        width: 252px;
      }
      &.label-1 {
        width: 210px;
      }

      @media (max-width: 1100px) {
        margin-top: 20px;
        font-size: 18px;
        line-height: 23px;

        &.label-9 {
          width: 116px;
        }
        &.label-7 {
          width: 132px;
        }
        &.label-3 {
          width: 154px;
        }
        &.label-1 {
          width: 125px;
        }
      }

      @media (max-width: 428px) {
        margin-top: 12px;
        width: 250px !important;
        font-size: 14px;
        line-height: 15px;
      }
    }

    @media (max-width: 428px) {
      margin-bottom: 28px;
    }
  }
`;

export default Supply;
